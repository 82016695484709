.footer-area {
    display: block;
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
}

.footer-top-area {
    background: #0e0a38;
    padding: 60px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-widget.logo {
    padding-right: 40px;
}

.footer-widget h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
}

.footer-widget li {
    padding: 0 0 15px;
}

.footer-widget li a {
    display: block;
    color: #dedede;
    font-weight: 400;
    font-family: 'Rubik';
}

.footer-widget li a:hover {
    color: #FF630E;
}

.footer-widget.footer-cat li i {
    color: #FF630E;
    padding-right: 5px
}

.footer-widget.logo img {
    max-width: 160px;
    margin-bottom: 20px;
}

.footer-widget.logo p {
    color: #dedede;
}

.footer-widget.logo img {
    max-width: 160px;
    margin-bottom: 20px;
}

.footer-widget.logo p,
.footer-bottom-area p {
    color: #dedede;
}

.footer-widget.logo h6 {
    color: #fff;
    font-weight: 700;
    margin: 25px 0 10px;
}

.footer-social-icons li {
    display: inline-block;
    padding-right: 15px;
}

.footer-social-icons li a,
.footer-widget.footer-contact li p {
    color: #fff;
}

.footer-social-icons li a:hover,
.recent-post-cont a:hover {
    color: #FF630E;
}

.footer-post-single {
    overflow: hidden;
    margin-bottom: 20px;
}

.recent-post-thumb {
    float: left;
    width: 30%;
}

.recent-post-cont {
    float: left;
    width: 70%;
    padding: 0 20px 0 10px;
}

.recent-post-thumb img {
    border-radius: 5px;
}

.recent-post-cont a {
    display: block;
    color: #dedede;
    font-family: 'Rubik';
    font-weight: 400;
}

.recent-post-cont span {
    color: #dedede;
    font-size: 13px;
    font-family: 'Rubik';
}

.footer-widget.footer-contact li {
    color: #dedede;
    font-family: 'Rubik';
    position: relative;
    padding-left: 35px;
    font-size: 15px;
    line-height: 25px;
}

.footer-widget.footer-contact li i {
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 6px;
    color: #FF630E;
}

.footer-widget.footer-contact li i.fa.fa-map-marker {
    font-size: 30px;
}

.footer-widget.footer-contact li i.fa.fa-phone {
    font-size: 24px;
}

.footer-widget.footer-contact .info {
    margin-top: 10px;
}

.footer-bottom-area {
    background: #0e0a38;
    padding: 20px 0;
    text-align: center;
}

.m-o {
    margin: 0rem !important;
}

.container {
    max-width: 1240px;
    margin: auto;
}

.ul-none {
    list-style: none;
}

.li-none {
    text-align: webkit-match-parent;
    padding: 2pxS;
}

.lines {
    margin: 0;
}

.grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
}

.hidden {
    visibility: hidden;
}