.main {
    display: grid;
    grid-template-columns: auto;
}











