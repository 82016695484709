.flex {
	/* display: -webkit-box;
	display: -ms-flexbox; */
	display: flex;
	/* -ms-flex-wrap: wrap; */
	flex-wrap: wrap;
	margin-right: 15px;
	margin-left: 15px;
    justify-content: center;
}

.container {
    max-width: 1240px;
    margin: auto;
}

.course-heading {
    text-align: center;
}

.sec-heading h2 {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FF630E;
    letter-spacing: 1px;
}