.section {
    height: 500px;
    background-color: beige;
}

.container {
    max-width: 1240px;
    margin: auto;
}

.header {
    /* background-color: aqua; */
    display: block;
}

.headerBottom {
    padding: 20px 0;
    transition: 0.5s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    position: relative;
    
}

.mainMenuWrap {
    display: flex;
    align-items: center;
}




