.container {
    max-width: 1240px;
    margin: auto;
}

.hero-area {
    background-color: #0e0a38;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    position: relative;
}

.hero-area:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 1, 51, 0.7);
}

.caption-content {
    position: relative;
    padding: 0 10%;
}

.caption-content h4 {
    color: #FF630E;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    font-weight: 600;
}

.caption-content h2 {
    font-size: 70px;
    line-height: 77px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

.caption-content p {
    color: #fff;
    padding: 0 40% 0 10px;
}

.caption-content ul {
    margin: 45px 0px 0;
}

.caption-content ul li {
    display: inline-block;
    margin-right: 19px;
}

.caption-content li a {
    display: block;
    background-color: #FF630E;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.caption-content li a:hover {
    color: #FF630E;
    background-color: #fff;
}

.caption-content li .btn-bg {
    background-color: #fff;
    color: #FF630E;
}

.caption-content li .btn-bg:hover {
    background-color: #FF630E;
    color: #fff;
}

.hero-area.two {
    /* background-image: url(../images/banner-4.jpg); */
}

.hero-area.two:before {
    background-color: transparent;
    background-image: linear-gradient(60deg, #050133 50%, #05013340 100%);
    opacity: 0.8;
}

.caption-content.two {
    padding-right: 20%;
    padding-left: 0
}

.caption-content.two p {
    padding: 0 200px 0 0;
}

.hero-area.three {
    /* background-image: url(../images/banner-5.jpg); */

}

.content h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    color: #fff;
}

.content p {
    color: #fff;
}

.course-search {
    position: relative;
    margin: 50px 100px 55px;
    padding: 30px;
    background-color: #FFFFFFBD;
    border-radius: 10px 10px 10px 10px;
}

.course-search input {
    height: 60px;
    padding-left: 20px;
    font-family: 'Rubik';
}

.search-btn {
    position: absolute;
    top: 35px;
    right: 31px;
    height: 50px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: #FF630E;
    color: #fff;
    font-size: 20px;
    margin-right: 5px;
    border: 0;
}

.hero-feat {
    padding: 0 20%;
}

.feat-icon img {
    width: 60px;
}

.feat-cont h3 {
    color: #fff;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    margin: 0;
}

.feat-cont p {
    color: #fff;
}

.feat-cont {
    padding-left: 20px;
}

.hero-area.four {
    /* background-image: url(../images/banner-6.jpg); */
    padding-bottom: 270px;
}
