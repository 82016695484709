.mainMenuArea {
    flex-basis: 55%;
    text-align: right;
}

.mainMenu ul li{
    display: inline-block;
    position: relative;
    padding: 0 10px;
}
.mainMenu ul li a {
    display: block;
    padding: 10px 5px;
    font-size: 19px;
    font-weight: 700;
    color: #130f40;
}

a {
    text-decoration: none;
}

.active {
    color: #FF630E;
}

.inactive {
    color: #000000;
}