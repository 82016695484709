/* login Register */
.logReg {
    flex-basis: 19%;
    text-align: right;
}

.logReg ul {
    background-color: #ff630e;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 20px;
}

.logReg ul li,
.logReg ul li a{
    display: inline-block;
    color: #fff;
    padding: 7px 0;
}
.logReg ul li small {
    padding: 0 10px;
}