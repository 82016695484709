.headerTop {
    background-color: #0e0a38;
    color: white;
    overflow: hidden;
    padding: 15px 0;
}

.headerContactInfo {
    flex-basis: 65%;
    padding-right: 25px;
    text-align: right;
}

.headerContactInfo address {
    color: #fff;
    display: inline-block;
}

.headerContactInfo address a {
    color: #fff;
    padding-right: 40px;
    text-decoration: none;
    font-style: normal;
}
